import http from '@/shared/http'
import apiResultWrapper from '@/shared/apiResultWrapper'
import { isNotNull } from '@/shared/StringUtil'

const fetchSymbolsByCriteria = async (page, size, sort, symbol, active) => {
  return await apiResultWrapper.apiResult(await http.get(`/api/v1/bloomberg/symbol/criteria?size=${size}&page=${page}${isNotNull(sort) ? `&sort=${sort}` : ""}${isNotNull(symbol) ? `&symbol=${symbol}` : ""}${isNotNull(active) ? `&active=${active}` : ""}`))
}

export default {
  fetchSymbolsByCriteria
}
