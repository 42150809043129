<template>
  <v-container fluid>
    <v-row>
      <v-col
      class="text-center text-uppercase">
        <span style="font-weight: 400; letter-spacing: 1px; font-size: 1.4rem">
          Symbols disabled while processing bloomberg data
        </span>
      </v-col>
    </v-row>
    <v-row>
      <v-col
      cols="10">
        <v-text-field
          v-model="search"
          :disabled="loading"
          label="Like search"
          filled
          clearable
          single-line
          hide-details
          v-on:keyup.enter="tryToSearch()"
        />
      </v-col>
      <v-col
        cols="2"
        class="d-flex justify-center"
      >
        <v-btn
          :loading="loading"
          :disabled="loading"
          color="primary"
          class="white--text btn-center mr-0"
          x-large
          @click="tryToSearch()"
        >
          Search
          <v-icon
            dark
            right
          >
            mdi-magnify
          </v-icon>
        </v-btn>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <main-data-table
        ref="mainDataTableRef"
        :url-queries="pagination.urlQueries"
        :headers="headers"
        :items="items"
        :loading="loading"
        :sort-field-map="pagination.sortFieldMap"
        :items-per-page.sync="pagination.size"
        :page.sync="pagination.page"
        :total-pages="pagination.totalPages"
        :total-elements="pagination.totalElements"
        @sort-by-changed="onSort"
        @query-changed="tryToFetchDisabledSymbols"
        loadingLabel="Loading... Please wait"
        hide-default-footer>

        </main-data-table>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import disabledSymbolsService from '@/service/DisabledSymbolService.js'
import MainDataTable from '@/components/MainDataTable'

export default {
  name: 'DisabledSymbols',
  components: {
    MainDataTable
  },
  props: {
  },
  data: () => ({
    items: [],
    headers: [
      { text: 'Symbol', value: 'symbol', align: 'center' },
      { text: 'Bloomberg symbol', value: 'bloombergSymbol', align: 'center' },
      { text: 'Reason for disabled', value: 'disabledReason', align: 'center', sortable: false }
    ],
    search: "",
    isActive: false,
    pagination: {
      urlQueries: true,
      sortFieldMap: {},
      page: 1,
      size: 1000,
      sort: null,
      totalPages: 1,
      totalElements: 0
    },
    loading: false
  }),
  computed: {
  },
  methods: {
    async tryToFetchDisabledSymbols() {
      try {
        this.loading = true
        const r = await disabledSymbolsService.fetchSymbolsByCriteria(
          this.pagination.page - 1 || 0,
          this.pagination.size || 1000,
          this.pagination.sort,
          this.search,
          this.isActive
        )

        if (r.status === 200) {
          const { data } = r

          this.items = data.content
          this.pagination.totalElements = data.totalElements
          this.pagination.totalPages = data.totalPages
        }
      }
      finally {
        this.loading = false
      }
    },
    async tryToSearch () {
      this.$refs.mainDataTableRef.updateRouterQueries({
        page: 1,
        size: this.pagination.size
      })
      await this.tryToFetchDisabledSymbols()
    },
    onSort(sort) {
      this.pagination.sort = !!sort ? sort : null
      this.tryToFetchDisabledSymbols()
    }
  }
}
</script>
<style lang="css">

</style>
